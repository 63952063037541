<template>
  <div class="footerSection page-footer">
    <div class="top-footer">
      <div class="wrapper menu-list">
        <div class="footer-menu">
          <h3>{{ $t("aboutAAAfx.name") }}</h3>
          <ul>
            <li>
              <router-link to="/why">{{
                $t("aboutAAAfx.submenu.why.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/safety-funds">{{
                $t("aboutAAAfx.submenu.safety.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/our-servers">{{
                $t("aboutAAAfx.submenu.servers.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/regulations">{{
                $t("aboutAAAfx.submenu.regulations.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/legal-documents">{{
                $t("aboutAAAfx.submenu.legalDocs.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/career">{{
                $t("aboutAAAfx.submenu.careers.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{
                $t("aboutAAAfx.submenu.contactUs.title")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-menu">
          <h3>{{ $t("trading.name") }}</h3>
          <ul>
            <li>
              <router-link to="/forex">{{
                $t("trading.submenu.products.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/account-types">{{
                $t("trading.submenu.accountTypes.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/trading-condition">{{
                $t("trading.submenu.tradingConditions.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/dep-with">{{
                $t("trading.submenu.depWithdrawal.title")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-menu">
          <h3>{{ $t("platforms.name") }}</h3>
          <ul>
            <li>
              <router-link to="/mt4">{{
                $t("platforms.submenu.mt4.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/zulu">{{
                $t("tools.submenu.zulu.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/compare-platform">{{
                $t("platforms.submenu.comparison.title")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-menu">
          <h3>{{ $t("promotions.name") }}</h3>
          <ul>
            <li>
              <router-link to="/vps-hosting">{{
                $t("promotions.submenu.vps.title")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-menu">
          <h3>{{ $t("tools.name") }}</h3>
          <ul>
            <li>
              <router-link to="/zulu-tools">{{
                $t("tools.submenu.zulu.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/trading-calculator">{{
                $t("tools.submenu.calculators.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/economic-calendar">{{
                $t("tools.submenu.ecoCalendar.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/pamm">{{
                $t("tools.submenu.partnerships.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/faq">{{
                $t("tools.submenu.faq.title")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mid-link">
      <div class="wrapper t-center">
        <ul>
          <li>
            <a href=" https://www.facebook.com/AAAFxOfficial" target="_blank"
              ><i class="fab fa-facebook-f"></i
            ></a>
          </li>
          <li>
            <a href="https://twitter.com/AAAFxOfficial" target="_blank"
              ><i class="fab fa-twitter"></i
            ></a>
          </li>
          <li>
            <a href="https://www.instagram.com/aaafxofficial/" target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/aaafx/" target="_blank"
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </li>
          <li>
            <a target="_blank" href="assets/docs/Privacy Policy.pdf"
              >{{ $t("privacypolicy") }}
            </a>
          </li>
          <li>
            <a target="_blank" href="assets/docs/Risk Disclaimer.pdf">
              {{ $t("riskdisc.name") }}
            </a>
          </li>
          <li>
            <a target="_blank" href="assets/docs/MiFID ΙΙ Directive Basics.pdf">
              {{ $t("mifid") }}
            </a>
          </li>
          <li>
            <router-link to="/legal-documents">{{
              $t("aboutAAAfx.submenu.legalDocs.title")
            }}</router-link>
          </li>
          <li>
            <a target="_blank" href="assets/docs/fatca.pdf">{{
              $t("fatca")
            }}</a>
          </li>
          <li>
            <a target="_blank" href="assets/docs/CRS.pdf">{{ $t("crs") }}</a>
          </li>
          <li>
            <router-link to="/legal-documents">{{ $t("keyinfo") }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom-footer">
      <div class="wrapper">
        <div class="img-holder t-center">
          <router-link to="/">
            <img src="assets/images/logo-footer.webp" alt="" />
          </router-link>
        </div>
        <div class="disclaimer">
          <p>
            <strong>{{ $t("legalinfo.name") }}: </strong> <br />

            <img src="assets/images/footer-flag2.webp" alt="" />
            {{ $t("aboutAAAfx.title") }}
            <br />
            <br />
            {{ $t("legalinfo.para4") }}
            <br />
          </p>
          <p :class="text_bold == true ? 'BoldText' : ''">
            <strong>{{ $t("riskdisc.name") }}:</strong>
            <span>{{ $t("riskdisc.para_one") }}</span>
            <span @mouseover="text_bold = true" @mouseleave="text_bold = false"
              >59, 75%*</span
            >
            <span>{{ $t("riskdisc.para_two") }}</span>
            <router-link to="/annual-losers">{{
              $t("riskdisc.link")
            }}</router-link
            ><br />
          </p>
        </div>
        <div class="disclaimer">
          <p :class="text_bold == true ? 'BoldText' : ''">
            <strong>{{ $t("riskdisc.acceptedLang") }}: </strong> <br />
            <span>{{ $t("riskdisc.countries") }} </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text_bold: false,
    };
  },
};
</script>
<style>
.BoldText {
  font-weight: 700;
}
</style>
